.provincial-view {
  padding: var(--bar-padding);

  &--header {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  &--list {
    list-style: none;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0rem 0;
    padding: 0rem 1rem 1rem 0;
    text-align: left;

    // fix overflow y affect overflow x and part of stackbard tooltip is hidden
    margin-left: -15rem;
    padding-left: 15rem;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
      border-radius: 1rem;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #f5f5f5;
      border-radius: 1rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #000000;
      border-radius: 1rem;
    }

    &-zone {
      font-size: 1.4rem;
    }

    &-item {
      font-size: 1.2rem;
      padding: 1rem 0;
      border-bottom: 1px solid black;
      font-family: 'Noto Sans Thai';
      // font-weight: 500;

      // &:first-child {
      //   border-top: 1px solid black;
      // }

      &__winner {
        font-size: 1.2rem;
      }
    }
  }

  &--toggle {
    display: flex;
    border: 1px solid var(--color-black);
    border-radius: var(--border-radius);
    background-color: var(--color-black);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
    overflow: hidden;
    position: relative;
    margin-bottom: 12.5px;
    height: 34px;

    &-button {
      flex: 0 0 50%;
      background-color: transparent;
      color: var(--color-white);
      border: none;
      outline: none;
      cursor: pointer;
      font-family: inherit;
      font-size: 1.8rem;
      z-index: 10;
      box-sizing: border-box;

      &.active {
        color: var(--color-black);
      }
    }
    &-active {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 50%;
      background-color: var(--color-white);
      border-radius: 1rem;
      transition: left 0.25s;
    }
  }

  @include tablet {
    padding-bottom: 4rem;

    &--header {
      font-size: 2rem;
    }
    &--list {
      max-height: initial;
    }
    &--content {
      max-height: 13rem;
      padding-bottom: 2rem;
      overflow: auto;
      transition: height .5s ease-out;

      .show-info & {
        height: calc(100vh - 30rem);
        max-height: initial;
        padding-bottom: 4rem;
      }
    }
  }
}

.compare {
  background-color: var(--color-white);
  border-radius: var(--border-radius);
}
