.party-list {
  &--list {
    list-style: none;
    // max-height: 35vh;
    // overflow-y: scroll;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
      border-radius: 1rem;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #f5f5f5;
      border-radius: 1rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #000000;
      border-radius: 1rem;
    }

    &-item {
      font-size: 1.4rem;
      padding: 0.5rem 0 0;
      font-family: 'Noto Sans Thai';

      &:first-child {
        border-bottom: 1px solid black;
        font-size: 1.8rem;
        padding-bottom: 0.5rem;
      }

      &:last-child {
        padding-bottom: 0.5rem;
        border-bottom: 1px solid black;
      }
    }
  }

  &--party-box {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    border-radius: 2px;
  }

  &--count {
    float: right;
  }
}
