@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap');

@font-face {
  font-family: 'Noto Sans';
  src: url('fonts/NotoSansThaiUI-Regular.woff2') format('woff2'),
    url('fonts/NotoSansThaiUI-Regular.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('fonts/NotoSans-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('fonts/NotoSansThaiUI-Bold.woff2') format('woff2'),
    url('fonts/NotoSansThaiUI-Bold.woff') format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'Noto Sans Thai';
  src: url('fonts/NotoSansThaiUI-Regular.woff2') format('woff2'),
    url('fonts/NotoSansThaiUI-Regular.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'Noto Sans Thai';
  src: url('fonts/NotoSansThaiUI-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Noto Sans Thai';
  src: url('fonts/NotoSansThaiUI-Bold.woff2') format('woff2'),
    url('fonts/NotoSansThaiUI-Bold.woff') format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'The MATTER';
  src: url('fonts/thematter-bold.ttf');
}
