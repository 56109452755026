#map {
  cursor: pointer;
}

.zone-label {
  text-anchor: middle;
  pointer-events: none;
}

.tooltips {
  position: absolute;
  background-color: var(--color-white);
  color: var(--color-black);
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 0.5rem 2.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 7.5px;
    height: 7.5px;
    transform-origin: 50% 50%;
    transform: rotate(45deg) translateX(-50%);
    background-color: #fff;
    left: 50%;
    top: calc(100% - 1.6px);
    z-index: 0;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 7.5px;
    height: 7.5px;
    transform-origin: 50% 50%;
    transform: rotate(45deg) translateX(-50%) translateZ(-1px);
    background-color: #fff;
    left: 50%;
    top: calc(100% - 1.6px);
    z-index: -1;
    // box-shadow: -1px -1px 20px;
  }
}
