.year-choice {
  &--list {
    list-style: none;

    display: flex;
    flex-flow: row wrap;
    width: 350px;
    margin-bottom: 23px;
  }

  &--list-item {
    font-size: 3.5rem;
    font-weight: bold;

    width: 170px;
    height: 65px;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--color-white);
    border-radius: calc(var(--border-radius) - 0.5rem);
    background-color: black;
    cursor: pointer;

    &:nth-child(odd) {
      margin-right: 10px;
    }

    &:hover {
      color: black;
      background-color: var(--color-white);
    }

    &__active {
      color: black;
      background-color: var(--color-white);
    }
  }
}
