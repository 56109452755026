:root {
  font-size: 62.5%;
  font-family: 'The MATTER', 'Noto Sans Thai', sans-serif;
  color: var(--color-white);
  line-height: 1.5;
}

html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  &,
  &:visited {
    color: var(--color-black);
  }
  &:active,
  &:focus,
  &:hover {
    color: #f0324b;
  }
}

body {
  background-color: var(--color-background);
}

.mobile-only {
  display: none !important;

  @include tablet {
    display: block;
  }
}

.detail-layer {
  z-index: 100;
  position: relative;
  // display: flex;
  // justify-content: space-between;
  // align-items: flex-start;
  height: calc(100vh - 6rem);
  padding: 0;
  pointer-events: none;
  min-width: 100rem;

  & * {
    pointer-events: auto;
  }

  @include tablet {
    // position: fixed; // fixed mobile bug where we can scroll y
    width: 100%;
    min-width: initial;
  }
}

.viz-layer {
  z-index: 1;
  position: fixed;
  top: 6rem;
  width: 100%;
  text-align: center;
}

.icon--chevron {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  border: 1px solid;
  border-radius: 100px;
  vertical-align: middle;

  &::after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 1rem;
    height: 1rem;
    border-bottom: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
    left: 0.9rem;
    top: 0.65rem;
  }

  &__right {
    &::after {
      transform: rotate(-45deg);
      left: 0.7rem;
      top: 0.85rem;
    }
  }

  &__left {
    &::after {
      transform: rotate(135deg);
      left: 1.1rem;
      top: 0.85rem;
    }
  }
}
