@import "../../../styles/_mixin.scss";

.dropdown {
  &--container {
    width: 100%;
    position: relative;
  }

  &--button {
    width: 100%;
    padding: var(--bar-padding);
    font-size: 3.2rem;
    font-family: inherit;
    height: 6.4rem;
    font-weight: bold;
    background-color: var(--color-white);
    border: 1px solid var(--color-black);
    border-radius: var(--border-radius);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
    outline: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    line-height: 4.2rem;
    cursor: pointer;
  }

  &--chevron {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 3rem;
    height: 3rem;
    border: 1px solid;
    border-radius: 100px;

    &::after {
      content: '';
      display: block;
      box-sizing: border-box;
      position: absolute;
      width: 1rem;
      height: 1rem;
      border-bottom: 1px solid;
      border-right: 1px solid;
      transform: rotate(45deg);
      left: 0.9rem;
      top: 0.65rem;
    }
  }

  &--items {
    position: absolute;
    top: 7rem;
    left: 0%;
    width: 100%;
    border: 1px solid var(--color-black);
    border-radius: var(--border-radius);
    box-shadow: 0 1px 2rem rgba(0, 0, 0, 0.3);
    z-index: 100;
    cursor: pointer;
    overflow: hidden;
    background-color: var(--color-white);

    &-wrapper {
      max-height: 30rem;
      overflow-y: scroll;
      overflow-x: hidden;
      margin: 1rem 0.5rem 1rem 0;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
        border-radius: 1rem;
      }

      &::-webkit-scrollbar {
        width: 6px;
        background-color: #f5f5f5;
        border-radius: 1rem;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #000000;
        border-radius: 1rem;
      }
    }
  }

  &--item {
    color: var(--color-black);
    padding: 0.5rem 1.5rem 0;
    font-size: 2rem;

    &:hover {
      color: var(--color-primary);
    }
  }

  &--search {
    width: 100%;
    text-align: center;

    &-input {
      width: 90%;
      padding: 1rem 0;
      margin: 0 1.5rem;
      font-size: 2rem;
      font-family: inherit;
      outline: none;
      border: none;
      border-bottom: 0.1rem solid var(--color-black);
    }
  }

  @include tablet {
    &--button {
      font-size: 2rem;
      height: auto;
      line-height: 1.5;
      padding: 0.5rem 1rem;
    }
    &--items {
      top: 4.5rem;
    }
    &--item {
      padding: 0.5rem 1.5rem 0;
      font-size: 1.6rem;
    }
  }
}
