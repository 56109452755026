.about-us {
  max-width: 90rem;
  margin: 0 auto;
  font-size: 1.6rem;
  font-family: 'Noto Sans', 'Noto Sans Thai', sans-serif;
  background-color: var(--color-white);
  color: var(--color-black);
  padding: 2rem 5rem;
  border-radius: 2.5rem;
  padding-bottom: 10rem;
  margin-bottom: 10rem;

  a {
    text-decoration: none;
    border-bottom: 1px dotted;
  }

  &--header {
    font-size: 4rem;
    text-align: center;
    margin-bottom: 2rem;
    font-family: 'The MATTER', 'Noto Sans Thai', sans-serif;
    font-weight: bold;
  }

  &--section {
    margin-bottom: 3rem;
    & + & {
      padding-top: 3rem;
      border-top: 4px solid var(--color-black);
    }

    h3 {
      font-size: 2.4rem;
      margin-bottom: 1rem;
    }

    .remarks {
      display: block;
      font-size: 1.2rem;
      margin: 0.5rem 0;
    }

    &__program,
    &__design {
      span + span {
        &::before {
          content: ', ';
        }
      }
    }
  }

  &--source {
    &-list-item + &-list-item {
      margin-top: 3rem;
    }

    &-list {
      list-style: none;
    }
  }

  @include tablet {
    width: 100vw;
    max-width: initial;
    border-radius: 0;
    padding: 2rem;

    &--header {
      font-size: 3rem;
    }
  }
}
