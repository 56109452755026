:root {
  --color-white: #ffffff;
  --color-black: black;
  --color-background: #262626;
  --color-nav: #222222;
  --color-pink: #fcbbdd;
  --color-primary: #ef314b;
  --border-radius: 1rem;
  --bar-padding: 1rem 2rem;
}
